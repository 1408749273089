import React from "react";
import logo from "./logo.svg";
import "./App.css";
import useNotifications from "./useNotifications";

function App() {
  const {
    token,
    response,
    onTokenChangeHandler,
    onRegisterForNotificationClicked,
    onUnregisterForNotificationClicked
  } = useNotifications();
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React v0.0.1
        </a>
        <p style={{ "white-space": "pre" }}>{JSON.stringify(response)}</p>
        <p>{token}</p>
        <button onClick={onRegisterForNotificationClicked}>
          Click here to receive notifications
        </button>
        <input onChange={onTokenChangeHandler} />
        <button onClick={onUnregisterForNotificationClicked}>
          Click here to stop receiving notifications
        </button>
      </header>
    </div>
  );
}

export default App;
