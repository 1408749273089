import { useState } from "react";
import { askForPermissioToReceiveNotifications } from "./push-notification";

const API_SERVER = "https://notify.gbl.ro/browsernotifyserver";

export default function useNotifications() {
  const [token, setToken] = useState(null);
  const [response, setResponse] = useState(null);
  const [inputToken, setInputToken] = useState(null);

  const unregisterForNotifications = (token) => {
    return fetch(`${API_SERVER}/unregister?token=${token}`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setResponse(json);
      })
      .catch((err) => {
        setResponse(err);
      });
  };

  const registerForNotifications = () => {
    return askForPermissioToReceiveNotifications().then((token) => {
      setToken(token);
      return fetch(`${API_SERVER}/register?token=${token}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setResponse(json);
        })
        .catch((err) => {
          setResponse(err);
        });
    });
  };

  const onTokenChangeHandler = (e) => {
    setInputToken(e.target.value);
  };

  const onUnregisterForNotificationClicked = () => {
    return unregisterForNotifications(inputToken);
  };

  const onRegisterForNotificationClicked = () => {
    return registerForNotifications();
  };

  //   useEffect(() => {
  //     registerForNotifications();
  //   });

  return {
    token,
    response,
    onTokenChangeHandler,
    onUnregisterForNotificationClicked,
    onRegisterForNotificationClicked,
  };
}
