import firebase from "firebase";

export const initializeFirebase = () => {
  var firebaseConfig = {
    apiKey: "AIzaSyAE1mtEucHWvbx2XDw99BuYcp5RNgaAHHE",
    authDomain: "dragulceo00.firebaseapp.com",
    databaseURL: "https://dragulceo00.firebaseio.com",
    projectId: "dragulceo00",
    storageBucket: "dragulceo00.appspot.com",
    messagingSenderId: "16331214142",
    appId: "1:16331214142:web:290d30d7f758d8964bb06f",
    measurementId: "G-371K0SPE4L"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
};

// navigator.serviceWorker
//     .register('/serviceWorker.js')
//     .then((registration) => {
//       firebase.messaging().useServiceWorker(registration);
//     });
// }

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();

    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log("user token: ", token);

    return token;
  } catch (error) {
    console.error(error);
  }
};
